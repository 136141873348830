$(document).ready(function () {

	$("a.gallery").fancybox({
		openEffect	: 'elastic',
		closeEffect	: 'elastic',
		autoHeight: 'true',
		autoWidth: 'true',
		autoResize: 'true',
		autoSize: 'false',
		width: 'auto',
		height: 'auto',
		helpers : {
			title : {
				type : 'inside'
			}
		}
	});
	$("fancia").fancybox();

	$('a.active').attr('href', '#');
	$('a.active').attr('onclick', 'return false;');
	var link = window.location.href;
	$('.menu2 li a[href=\"' + link + '\"]').parent().parent().addClass('active');
	$('.files li a[href=\"' + link + '\"]').parent().parent().addClass('active');
	$(function () {
		$('li.active a').each(function () {
			var location = window.location.href;
			var link = this.href;
			if (location == link) {
				var tx = $(this).html();
				$(this).attr('onclick', 'return false;');
				$(this).parent().html(tx);
			}
		});
	});
	$(".img_fancy").fancybox({});
	$(window).scroll(function () {
		if ($(this).scrollTop() > 500) {
			if ($('#upbutton').is(':hidden')) {
				$('#upbutton').css({opacity: 1}).fadeIn('slow');
			}
		} else {
			$('#upbutton').stop(true, false).fadeOut('fast');
		}

	});
	$('#upbutton').click(function () {
		$('html, body').stop().animate({scrollTop: 0}, 300);
	});

	$(".urist_btn").fancybox({
		'overlayShow': true,
		'titlePosition': 'inside',
		'transitionIn': 'none',
		'transitionOut': 'none',
		'type': 'ajax',
		'href': '/bitrix/templates/new/forms_popup/popup_form_yurist/form.php',
		'centerOnScroll': true,
	});
	$('.fancia').fancybox(
		{
			type: 'iframe',
			iframe: {
				scrolling: 'auto',
				preload: true
			}
		});

	$(".header_phone_btn").fancybox({
		'overlayShow': true,
		'titlePosition': 'inside',
		'transitionIn': 'none',
		'transitionOut': 'none',
		'type': 'ajax',
		'href': '/bitrix/templates/new/forms_popup/popup_form_zvonok/form.php',
		'centerOnScroll': true,
	});

	a = $('h1').html();
	$(".ostavit_zayavku").fancybox({
		'overlayShow': true,
		'titlePosition': 'inside',
		'transitionIn': 'none',
		'transitionOut': 'none',
		'type': 'ajax',
		'href': '/bitrix/templates/new/forms_popup/popup_form_zayavka_nedvizhimost/form.php',
		'centerOnScroll': true,
		ajax: {
			type: "POST",
			data: 'mydata=' + a,
		}
	});

	$("#vopros_prodavcu_text").fancybox({
		'overlayShow': true,
		'titlePosition': 'inside',
		'transitionIn': 'none',
		'transitionOut': 'none',
		'type': 'ajax',
		'href': '/bitrix/templates/new/forms_popup/popup_form_vopros_prodavcu/form.php',
		'centerOnScroll': true,
	});

	$(".header_uslugi_btn").fancybox({
		'overlayShow': true,
		'titlePosition': 'inside',
		'transitionIn': 'none',
		'transitionOut': 'none',
		'type': 'ajax',
		'href': '/bitrix/templates/new/forms_popup/popup_form_zayavka_uslugi/form.php',
		'centerOnScroll': true,
	});

	$(".uslug_btn").fancybox({
		'overlayShow': true,
		'titlePosition': 'inside',
		'transitionIn': 'none',
		'transitionOut': 'none',
		'type': 'ajax',
		'href': '/bitrix/templates/new/forms_popup/popup_form_zayavka_uslugi_vnutr/form.php',
		'centerOnScroll': true,
	});

	$('.akciya_main_btns .akciya_main_zakaz').click(function () {
		$('.akciya_main_form_zakaz.popup_forms').slideToggle();
	});

});